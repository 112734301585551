import BackupIcon from '@mui/icons-material/Backup';
import CenterIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import ChatroomIcon from '@mui/icons-material/Chat';
import NoticeIcon from '@mui/icons-material/Description';
import CenterClubIcon from '@mui/icons-material/Diversity3';
import GameIcon from '@mui/icons-material/EmojiEvents';
import TrainerIcon from '@mui/icons-material/Face';
import LessonIcon from '@mui/icons-material/Groups';
import PolicyIcon from '@mui/icons-material/ImportContacts';
import MallProductIcon from '@mui/icons-material/LocalOffer';
import MembershipIcon from '@mui/icons-material/Loyalty';
import UserIcon from '@mui/icons-material/People';
import BannerIcon from '@mui/icons-material/PersonalVideo';
import VirtualNumberIcon from '@mui/icons-material/Phone';
import FaqIcon from '@mui/icons-material/QuestionAnswer';
import ReviewIcon from '@mui/icons-material/RateReview';
import ReportIcon from '@mui/icons-material/ReportProblem';
import ScoreIcon from '@mui/icons-material/Scoreboard';
import SettingIcon from '@mui/icons-material/Settings';
import MallOrderIcon from '@mui/icons-material/ShoppingCart';
import MovieIcon from '@mui/icons-material/Slideshow';
import CenterEventIcon from '@mui/icons-material/SportsEsports';
import DrillerIcon from '@mui/icons-material/Storefront';
import TemplateIcon from '@mui/icons-material/TextFields';
import PopupNoticeIcon from '@mui/icons-material/Today';
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import LogIcon from '@mui/icons-material/ViewHeadline';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import koreanMessages from 'ra-language-korean';
import {Admin, CustomRoutes, Resource} from 'react-admin';
import {Route} from 'react-router-dom';
import {RoleType} from './common/types';
import useBadge from './hooks/useBadge';
import Layout from './layout/Layout';
import ConfigurationPage from './pages/ConfigurationPage';
import LoginPage from './pages/LoginPage';
import {BackupList} from './routes/backups';
import {BannerCreate, BannerEdit, BannerList} from './routes/banners';
import {CategoryCreate, CategoryEdit, CategoryList} from './routes/categories';
import {CenterClubEdit, CenterClubList} from './routes/center-clubs';
import {CenterEventEdit, CenterEventList} from './routes/center-events';
import {CenterReviewEdit, CenterReviewList} from './routes/center-reviews';
import {CenterCreate, CenterEdit, CenterList} from './routes/centers';
import {CertificateList} from './routes/certificates';
import {ChatroomEdit, ChatroomList} from './routes/chatrooms';
import {DrillerEdit, DrillerList} from './routes/drillers';
import {FaqCreate, FaqEdit, FaqList} from './routes/faqs';
import {GalleryEdit, GalleryList} from './routes/galleries';
import {GameCreate, GameEdit, GameList} from './routes/games';
import {LessonEdit, LessonList} from './routes/lessons';
import {LogEdit, LogList} from './routes/logs';
import {MallOrderEdit, MallOrderList} from './routes/mall-orders';
import {
  MallProductReviewEdit,
  MallProductReviewList,
} from './routes/mall-product-reviews';
import {
  MallProductCreate,
  MallProductEdit,
  MallProductList,
} from './routes/mall-products';
import {MembershipList} from './routes/memberships';
import {MovieCreate, MovieEdit, MovieList} from './routes/movies';
import {NoticeCreate, NoticeEdit, NoticeList} from './routes/notices';
import {ParticipationEdit} from './routes/participations';
import {PickCenterEdit, PickCenterList} from './routes/pick-centers';
import {PickDayScoreList} from './routes/pick-day-scores';
import {PickGameCreate, PickGameEdit, PickGameList} from './routes/pick-games';
import {PickRankList} from './routes/pick-ranks';
import {PolicyEdit, PolicyList} from './routes/policies';
import {
  PopupNoticeCreate,
  PopupNoticeEdit,
  PopupNoticeList,
} from './routes/popup-notices';
import {ProductEdit} from './routes/products';
import {ReportEdit, ReportList} from './routes/reports';
import {ReviewEdit, ReviewList} from './routes/reviews';
import {SettingEdit, SettingList} from './routes/settings';
import {TemplateEdit, TemplateList} from './routes/templates';
import {TrainerEdit, TrainerList} from './routes/trainers';
import {UserCreate, UserEdit, UserList} from './routes/users';
import {VirtualNumberList} from './routes/virtualNumbers';
import authProvider from './utils/authProvider';
import dataProvider from './utils/dataProvider';

const messages = {
  kr: {
    ra: {
      ...koreanMessages.ra,
      page: {
        ...koreanMessages.ra.page,
        empty: '아무것도 없습니다',
        invite: '생성을 눌러서 만드실 수 있습니다',
      },
      auth: {
        auth_check_error: '권한이 없습니다',
        logout: '로그아웃',
      },
    },
  },
};

const i18nProvider = polyglotI18nProvider(
  // @ts-ignore
  (locale: any) => messages[locale],
  'kr',
  {
    allowMissing: true,
  },
);

function App() {
  const {
    uncheckReportCount,
    inspectingTrainerCount,
    inspectingDrillerCount,
    pendingGalleryCount,
  } = useBadge();

  return (
    <Admin
      title=""
      loginPage={LoginPage}
      layout={Layout}
      // dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}>
      {role => {
        return [
          <CustomRoutes>
            <Route path="/configuration" element={<ConfigurationPage />} />
          </CustomRoutes>,
          <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={role === RoleType.ADMIN ? UserCreate : undefined}
            options={{label: '회원'}}
            icon={UserIcon}
          />,
          <Resource
            name="centers"
            list={CenterList}
            edit={CenterEdit}
            create={role === RoleType.ADMIN ? CenterCreate : undefined}
            options={{label: '센터'}}
            icon={CenterIcon}
          />,
          <Resource
            name="center-clubs"
            list={CenterClubList}
            edit={CenterClubEdit}
            options={{label: '센터 클럽'}}
            icon={CenterClubIcon}
          />,
          <Resource
            name="center-events"
            list={CenterEventList}
            edit={CenterEventEdit}
            options={{label: '센터 이벤트'}}
            icon={CenterEventIcon}
          />,
          <Resource
            name="center-reviews"
            list={CenterReviewList}
            edit={CenterReviewEdit}
            options={{label: '센터 리뷰'}}
            icon={ReviewIcon}
          />,
          <Resource
            name="trainers"
            list={TrainerList}
            edit={TrainerEdit}
            options={{
              label:
                inspectingTrainerCount > 0
                  ? `트레이너(${inspectingTrainerCount})`
                  : '트레이너',
            }}
            icon={TrainerIcon}
          />,
          <Resource
            name="drillers"
            list={DrillerList}
            edit={DrillerEdit}
            options={{
              label:
                inspectingDrillerCount > 0
                  ? `프로샵(${inspectingDrillerCount})`
                  : '프로샵',
            }}
            icon={DrillerIcon}
          />,
          <Resource
            name="lessons"
            list={LessonList}
            edit={LessonEdit}
            options={{label: '그룹레슨'}}
            icon={LessonIcon}
          />,
          <Resource
            name="certificates"
            list={CertificateList}
            options={{label: '수강관리'}}
            icon={CertificateIcon}
          />,
          <Resource
            name="memberships"
            list={MembershipList}
            options={{label: '맴버십관리'}}
            icon={MembershipIcon}
          />,
          <Resource
            name="galleries"
            list={GalleryList}
            edit={GalleryEdit}
            options={{
              label:
                pendingGalleryCount > 0
                  ? `파트너컨텐츠(${pendingGalleryCount})`
                  : '파트너컨텐츠',
            }}
            icon={MovieIcon}
          />,
          <Resource
            name="movies"
            list={MovieList}
            edit={MovieEdit}
            create={role === RoleType.ADMIN ? MovieCreate : undefined}
            options={{label: '볼닥앤플 합성영상'}}
            icon={MovieIcon}
          />,
          <Resource
            name="mall-products"
            list={MallProductList}
            edit={MallProductEdit}
            create={MallProductCreate}
            options={{label: '쇼핑몰 상품'}}
            icon={MallProductIcon}
          />,

          <Resource
            name="mall-orders"
            list={MallOrderList}
            edit={MallOrderEdit}
            options={{label: '쇼핑몰 주문'}}
            icon={MallOrderIcon}
          />,
          <Resource
            name="mall-product-reviews"
            list={MallProductReviewList}
            edit={MallProductReviewEdit}
            options={{label: '쇼핑몰 상품 리뷰'}}
            icon={ReviewIcon}
          />,

          <Resource
            name="pick-centers"
            list={PickCenterList}
            edit={PickCenterEdit}
            options={{label: '볼링픽 제휴 볼링장'}}
            icon={CenterIcon}
          />,

          <Resource
            name="pick-games"
            list={PickGameList}
            edit={PickGameEdit}
            create={role === RoleType.ADMIN ? PickGameCreate : undefined}
            options={{label: '픽스코어 게임'}}
            icon={ScoreIcon}
          />,
          <Resource
            name="pick-day-scores"
            list={PickDayScoreList}
            options={{label: '픽스코어 일간점수'}}
            icon={ScoreIcon}
          />,
          <Resource
            name="pick-ranks"
            list={PickRankList}
            options={{label: '픽스코어 순위(3개월)'}}
            icon={ScoreIcon}
          />,
          <Resource
            name="games"
            list={GameList}
            edit={GameEdit}
            create={role === RoleType.ADMIN ? GameCreate : undefined}
            options={{label: '이벤트'}}
            icon={GameIcon}
          />,
          <Resource
            name="reviews"
            list={ReviewList}
            edit={ReviewEdit}
            options={{label: '리뷰'}}
            icon={ReviewIcon}
          />,
          <Resource
            name="chatrooms"
            list={ChatroomList}
            edit={ChatroomEdit}
            options={{label: '채팅방'}}
            icon={ChatroomIcon}
          />,
          <Resource
            name="categories"
            list={CategoryList}
            edit={CategoryEdit}
            create={role === RoleType.ADMIN ? CategoryCreate : undefined}
            options={{label: '카테고리'}}
            icon={CategoryIcon}
          />,
          <Resource
            name="banners"
            list={BannerList}
            edit={BannerEdit}
            create={role === RoleType.ADMIN ? BannerCreate : undefined}
            options={{label: '배너'}}
            icon={BannerIcon}
          />,
          <Resource
            name="popup-notices"
            list={PopupNoticeList}
            edit={PopupNoticeEdit}
            create={role === RoleType.ADMIN ? PopupNoticeCreate : undefined}
            options={{label: '팝업공지사항'}}
            icon={PopupNoticeIcon}
          />,
          <Resource
            name="virtual-numbers"
            list={VirtualNumberList}
            options={{label: '가상번호(비즈콜)'}}
            icon={VirtualNumberIcon}
          />,
          <Resource
            name="templates"
            list={TemplateList}
            edit={TemplateEdit}
            options={{label: '템플릿'}}
            icon={TemplateIcon}
          />,

          <Resource
            name="notices"
            list={NoticeList}
            edit={NoticeEdit}
            create={role === RoleType.ADMIN ? NoticeCreate : undefined}
            options={{label: '공지사항'}}
            icon={NoticeIcon}
          />,

          <Resource
            name="faqs"
            list={FaqList}
            edit={FaqEdit}
            create={role === RoleType.ADMIN ? FaqCreate : undefined}
            options={{label: 'FAQ'}}
            icon={FaqIcon}
          />,
          <Resource
            name="reports"
            list={ReportList}
            edit={ReportEdit}
            options={{
              label:
                uncheckReportCount > 0
                  ? `신고/권한요청(${uncheckReportCount})`
                  : '신고/권한요청',
            }}
            icon={ReportIcon}
          />,

          <Resource
            name="policies"
            list={PolicyList}
            edit={PolicyEdit}
            options={{label: '약관'}}
            icon={PolicyIcon}
          />,
          <Resource
            name="settings"
            list={SettingList}
            edit={SettingEdit}
            options={{label: '설정'}}
            icon={SettingIcon}
          />,
          <Resource
            name="logs"
            list={LogList}
            edit={LogEdit}
            options={{label: '로그'}}
            icon={LogIcon}
          />,
          <Resource
            name="backups"
            list={BackupList}
            options={{label: '백업'}}
            icon={BackupIcon}
          />,
          <Resource name="products" edit={ProductEdit} />,
          <Resource name="participations" edit={ParticipationEdit} />,
        ];
      }}
    </Admin>
  );
}

export default App;
