import {ID} from '.';
import {MallAddressArea} from './MallAddress.type';

export enum MallOrderState {
  IDLE = 'idle', // (결제대기)결제대기
  PAID = 'paid', // (결제완료)결제완료          - 취소가능
  PACKING = 'packing', //  상품준비중           - 취소 요청해야함
  PREPARING = 'preparing', // 배송준비중
  SHIPPING = 'shipping', // 배송중
  ARRIVED = 'arrived', // 배송완료
  CLOSED = 'closed', // 구매확정완료
  CANCELED = 'canceled', // 취소
  REFUNDED = 'refunded', // 환불
}

export const MallOrderStateNames = {
  idle: '결제대기',
  paid: '결제완료',
  packing: '상품준비중',
  preparing: '배송준비중', // information_received, at_pickup,(배송조회api매핑)
  shipping: '배송중', // out_for_delivery, in_transit(배송조회api매핑)
  arrived: '배송완료', // delivered(배송조회api매핑)
  closed: '주문완료',
  canceled: '취소',
  refunded: '반품',
};

export interface TrackingInfo {
  from: {
    name: string;
    time: string;
  };
  to: {
    name: string;
    time: string;
  };
  state: MallOrderState;
  progresses: {
    time: string;
    location: string;
    state: MallOrderState;
    description: string;
  }[];
}

export interface IMallOrder {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  orderNumber: string;

  state: MallOrderState;
  inRequestCancel: boolean;
  requestCancelAt?: Date;

  paidAt?: Date;
  packingAt?: Date;
  preparingAt?: Date;
  shippingAt?: Date;
  arrivedAt?: Date;
  closedAt?: Date;
  canceledAt?: Date;
  refundedAt?: Date;

  mallProduct: ID;
  productTitle: string;
  productPrice: number;
  productQuantity: number;

  //
  // 취소되어도 값은 변함 없음
  productAmount: number; // 상품 총합(배송비 제외)
  shippingAmount: number;
  totalAmount: number;

  refundMoneyAmount?: number; // 환불 금액

  payment: ID;
  user: ID;

  trackId?: string;

  review?: ID;

  addressArea: MallAddressArea;
  addressReceiver: string;
  addressPhone: string;
  zipCode: string;
  address: string;
  shippingMemo?: string;

  cancelReason?: string;

  trackingInfo?: TrackingInfo;

  mallProductEntity?: {
    id: ID;
    title: string;
    brandCategory: ID;
    cover: string;
  };
}
